.blogs {
    margin-top: 10rem;
    display: flex;
    flex-direction:row;
    gap: 10vw;    
    justify-content: center;
}



.blogs img{
    width: 300px;  
    height: 300px;  
    object-fit: cover; 
    box-shadow: 0px 0px 8px var(--primary-color);
    border: 10px black;
    border-radius: 10px; 
}

.blogs a:link {
    text-align: center;
    text-decoration: none;
}

.blogs h6 {
    color: black;
    font-size: calc(1rem + 1vw);
}


@media screen and (max-width:820px) {
    .blogs {
        /* gap: 3rem; */
    }

    .blogs img{
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width:600px) {
    .blogs {
        gap: 2rem;
    }

    .blogs img{
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width:450px) {
    .blogs {
        /* gap: 3rem; */
        margin-top: 5rem;
    }

    .blogs img{
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width:350px) {
    .blogs {
        /* gap: 3rem; */
    }

    .blogs img{
        width: 100px;
        height: 100px;
    }
}