.subscription h1 {
    color: var(--primary-color);
    font-size: calc(1rem + 2vw);
    text-align: center;
    padding-bottom: 2vh;
}

.sub-form {
    position: relative;
    width: 100%;
    max-width: 350px;
    height: 100%;
    margin: auto;
    border-radius: 20px;
    padding: 40px;
    box-sizing: border-box;
    background: #ecf0f3;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.sub-inputs {
    text-align: center;
    margin-top: 30px;    
}


.sub-form button {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
}

.sub-checkboxes {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: calc(1rem + 0.1vw);
    justify-content: center;
}

.sub-checkboxes p {
    font-size: calc(1rem + 0.3vw);
    color: var(--primary-color);
    text-align: center;
}

.sub-checkboxes label {
    text-align: center;
    padding:5px;
}

.sub-name, .sub-email{
    background: white;
    height: 50px;
    font-size: calc(1rem + 0.3vw);
    box-shadow: inset 2px 2px 2px #cbced1, inset -2px -2px 2px white;
    border: 1px solid var(--primary-color);
    width: 100%;
}

.sub-button{
    color: white;
    margin-top: 20px;
    background: var(--primary-color);
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    cursor: pointer;
}

.sub-checkboxes input {
  font-size: calc(1rem + 0.3vw);
}


.sub-email, input, select, textarea{
    font-size: calc(1rem + 0.01vw);
}

.sub-name, input, select, textarea{
  font-size: calc(1rem + 0.01vw);
  text-align: center;
}

.sub-error{
  color: red;
  text-align: center;
}

.sub-form img {
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 820px){
    .sub-checkboxes{
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .sub-form {
        width: 70%;
    }

    .sub-checkboxes {
        text-align: center;
    }
}

@media screen and (max-width: 420px) {
    .sub-form {
        width: 70%;
    }

    .sub-name, .sub-email{
        background: white;
        padding: 5px;
        height: 50px;
        font-size: calc(1rem + 0.3vw);
        box-shadow: inset 2px 2px 2px #cbced1, inset -2px -2px 2px white;
    }

    .sub-email, input, select, textarea{
        font-size: calc(0.8rem + 0.01vw);
    }
    
    .sub-name, input, select, textarea{
      font-size: calc(0.8rem + 0.01vw);

    }

    .sub-checkboxes {
        display: inline-block;
        font-size: calc(0.8rem + 0.1vw);
        
    }

}