
.quotes {
    text-align: center;
    padding-left: 6vw;
    padding-right: 6vw;
}


@media screen and (max-width:820px) {
    .quotes {
        padding-left: 0vw;
        padding-right: 0vw;
    }
}

