:root {
  --primary-color: #4d98ee;
  --secondary-color: rgb(251, 251, 251);
  background-color: var(--secondary-color);
  /* height: 100%; */
}

.container {
  margin-top: 100px;
  margin-bottom: 100px;
  padding-left: 8vw;
  padding-right: 8vw; 
} 


@media all and (max-width:600px) {
  .container{
    padding-left: 0vw;
    padding-right: 0vw;
    
  }
}

@media all and (max-width:400px) {
  .container{
    margin-top: 20px;
    
  }
}