

.merlin h1 {
    font-size: calc(1rem + 0.8vw);
    text-align: center;
    padding-bottom: 4vh;
}

.merlin-content {
    
    display: flex;
    flex-direction: row;
    gap: 20px;
}


.merlin-text{
    display: flex;
    flex-direction: column;
    font-size: 22px;
    width: 700px;
}

.merlin-mission{
    background-color: lightgrey;
    padding: 10px;
    font-style: italic;
    border-radius: 20px;
    border: 1px solid black;
}

.merlin-content img {
    box-shadow: 0px 0px 8px var(black);
    border: 10px black;
    border-radius: 10px; 
}

@media screen and (max-width:1390px) {

    .merlin {
        text-align: center;
        
    }
    .merlin-content {
        display: inline;
    }
   
    .merlin-content img {
        max-width: 100%;
        height: auto;
    } 

    .merlin-text {
        font-size: calc(1rem + 0.5vw);
        width: 90%;
        padding-left: 4vw;
    }

    .merlin-mission {
        text-align: left;
    }
}

@media screen and (max-width:820px) {

    
    .merlin-content img {
        max-width: 60%;
        height: auto;
    } 

    .merlin-text {
        font-size: calc(1rem + 0.5vw);
        width: 90%;
        padding-left: 4vw;
    }

}

@media screen and (max-width:600px) {

    
    .merlin-content img {
        max-width: 50%;
        height: auto;
    } 

    .merlin-text {
        font-size: calc(1rem + 0.3vw);
        width: 90%;
        padding-left: 4vw;
    }

}

@media screen and (max-width:400px) {

    
    .merlin-content img {
        max-width: 50%;
        height: auto;
    } 

    .merlin-text {
        font-size: calc(0.8rem + 0.1vw);
        width: 90%;
        padding-left: 4vw;
    }

}