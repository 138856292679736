.posts{
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    
}

.post{
    margin-top: 5vh;
    display: flex;
    gap: 5vw; 
}

.post-img{
    width: 300px;
    height: 200px;
    object-fit: cover;
    box-shadow: 0px 0px 8px var(--primary-color);
    border: 10px black;
    border-radius: 10px;  
}

.blog-content{
    margin-bottom: 12vh;  
}

.post-img img {
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.post h1{
    font-size: calc(1rem + 1.8vw);
    color: var(--primary-color)
}

.post p {
    font-size: calc(1rem + 0.5vw);
}

.post .post-link{
    text-decoration: none;
}

.blog-pagination{
    list-style:none;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap: 5px;
}

.blog-pagination .blog-page-num{
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
}

.blog-pagination .blog-page-num:hover{
    background-color: var(--primary-color);
    color: white

}

.blog-pagination .blog-page-active{
    background-color: var(--primary-color)
}

@media screen and (max-width: 820px) {
    .post-img {
        width: 200px;
        height: 150px;
        
    }

    .post-img img {
        width: 200px;
        height: 150px;
        
    }

}

@media screen and (max-width: 600px) {
    .blog-content{
        margin-bottom: 1vh;  
    }

    .post h1{
        font-size: calc(0.8rem + 0.8vw);
        color: var(--primary-color)
    }
    
    .post p {
        font-size: calc(0.8rem + 0.2vw);
    }

    .post-img {
        width: 150px;
        height: 100px;
        
    }

    .post-img img {
        width: 150px;
        height: 100px;
        
    }

    .blog-pagination{
        font-size: 0.8rem;
        padding-right: 14vw;
    }

}
