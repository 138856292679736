
/* .photos {
    margin-left: 350px;
    margin-right: 350px;
} */

.photos {
    margin-left: 18vw;
    margin-right: 18vw;
}


.photo-grid {
    /* margin-top:10.67px;
    padding-top:1.67px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    /* width: 100%;  
    height: 100%;  */
    overflow-y: auto; 
  }

.photoImg {
    margin: auto;
    /* grid-gap: -100px; */
}

.photoImg img {
    width: 15.625vw;  
    height: 32.01vh;  
    object-fit: cover; 
    box-shadow: 0px 0px 8px var(--primary-color);
    border: 10px black;
    border-radius: 10px;  
  }
  
  .photos-pagination{
    margin-top: 100px;
    list-style:none;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap: 5px;
}

.photos-pagination .photos-page-num{
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
}

.photos-pagination .photos-page-num:hover{
    background-color: var(--primary-color);
    color: white

}

.photos-pagination .photos-page-active{
    background-color: var(--primary-color)
}

@media screen and (max-width:1200px){
    .photoImg img {
        width: 16vw;  
        height: 25vh;  
      }
}

@media screen and (max-width:1000px){
    .photoImg img {
        width: 16vw;  
        height: 22vh;  
      }
    .photos-pagination{
        font-size: 1rem;
        padding-right: 8vw;
    }
}

@media screen and (max-width:820px){
    .photoImg img {
        width: 16vw;  
        height: 18vh;  
      }
    .photos-pagination{
        font-size: 1rem;
        padding-right: 10vw;
    }
}

@media screen and (max-width:720px){
    .photoImg img {
        width: 16vw;  
        height: 16vh;  
      }
    .photos-pagination{
        font-size: 1rem;
        padding-right: 12vw;
    }
}

@media screen and (max-width:600px){
    .photoImg img {
        width: 18vw;  
        height: 13vh;  
      }

    .photos-pagination{
        font-size: 1rem;
        padding-right: 13vw;
    }
}

@media screen and (max-width:400px){
    .photoImg img {
        width: 18vw;  
        height: 10vh;  
      }
    .photos-pagination{
        font-size: 0.8rem;
        padding-right: 13vw;
    }
}