.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
}

.photo-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.photo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo-content img{
    max-height:  85.37vh;
    max-width: 41.66vw;
    gap:20px;
    box-shadow: 0px 0px 5px var(--primary-color);
    border: 10px black;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
}

.photo-content p{
    font-size: calc(0.5rem + 0.5vw)
}

.photo-content p:last-child{
    font-size: calc(0.8rem + 0.5vw);
}

.photo-navigate button {
    cursor: pointer;
    color: white;
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    width: 50px;
}

.photo-delete button{
    cursor: pointer;
    color: white;
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    width: 100px;
}

@media (max-width: 768px) {

    .photo {
        align-items: center; /* Center vertically on mobile */
        height: auto; /* Adjust height as needed */
    }
}