.QuoteFig {
    color: black;
}

.QuoteFig > figcaption {
    /* text-align: center; */
    font-size: calc(1rem + 0.2vw);
}

.QuoteFig > blockquote {
    /* text-align: center; */
    font-size: calc(1rem + 0.6vw);
}


/* @media screen and (max-width: 820px) {
    .QuoteFig > figcaption {
        font-size: calc(1rem + 0.4vw);
    }

    .QuoteFig > blockquote {
        font-size: calc(1rem + 0.8vw);
    }
}

@media screen and (max-width: 600px) {
    .QuoteFig > figcaption {
        font-size: calc(1rem + 0.4vw);
    }

    .QuoteFig > blockquote {
        font-size: calc(1rem + 0.8vw);
    }
}

@media screen and (max-width: 400px) {
    .QuoteFig > figcaption {
        font-size: 0.6rem;
    }

    .QuoteFig > blockquote {
        font-size: 0.8rem;
    }
} */

