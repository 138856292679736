.auth {
    margin-top: 16vh;
}

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.auth-form input {
    width: 180px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    border: 0.0625em solid black;
}

.auth-form input::placeholder {
    text-align: center;
    color: var(black)
}

.auth-form button {
    width: 100px;
    height: 25px;
    background-color: var(--primary-color);
    border: none;
    cursor: pointer;
    box-shadow: 0.063em 0.063em 0.063em;
    font-size: 14px;
}


