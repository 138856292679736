.single {
    display: flex;
    flex-direction: column;
}

.ql-align-center {
    text-align: center;
}

.single a {
    color: blue;
}

.single blockquote {
    display: table;
    margin: 0 auto;
    padding: 10px;
    background-color: #f0f0f0;
    border-left: 4px solid #ccc;
  }

.single p {
    font-size: calc(1rem + 0.3vw);
}

.single .single-content h1 {
    text-align: center;
    font-size: calc(1rem + 3vw);
}

.single-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.single-edit {
    color: white;
    margin-top: 10px;
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    cursor: pointer;
    width: 60px;
}

.single-delete{
    color: white;
    margin-top: 10px;
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    cursor: pointer;
    width: 60px;
}

.single-link{
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .single p {
        font-size: calc(1rem + 0.1vw);
    }

    .container {
        padding-left: 4vw;
        padding-right: 4vw;
    }
}

@media screen and (max-width: 400px) {
    .single p {
        font-size: calc(0.7rem + 0.01vw)
    }
}