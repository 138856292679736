
.manage-sub h1 {
    color: var(--primary-color);
    font-size: calc(1rem + 1vw);
    text-align: center;
    margin-bottom: 6vh;
    
}

.manage-email {
    background: white;
    padding: 10px;
    margin: auto;
    width: 350px;
    height: 50px;
    margin-bottom: 10px;
    font-size: calc(1rem + 0.03vw);
    border: 1px solid var(--primary-color);
    box-shadow: inset 2px 2px 2px #cbced1, inset -2px -2px 2px #cbced1;
}

.manage-sub button{
    color: white;
    margin-top: 10px;
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    cursor: pointer;
    margin: auto;
    width: 200px;
    margin-top: 10px;
}

.manage-sub-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manage-sub .manage-checkbox{
    font-size: 16px;
    margin-top: 10px;
}

@media screen and (max-width: 400px) {
    .manage-email {
        width: 60%;
    }

    .manage-sub button{
        width: 45%;
    }
}