.navbar-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.0625rem solid lightgrey;
    background-color: white;
}

.logo {
    gap: 1rem;
    margin-top: 0.625rem;
    flex: 1;
    left:0;
    padding-left: 1rem;
}

.navbar-active {
    flex: 3;
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-left: 3rem;
}

.merlin-pic {
    flex: 1;
    text-align: right;
    right: 0;
    margin-right: 1rem;
}

.navbar-right {
    display: flex;
    flex-direction: column;
    padding-right: 0.3rem;
    text-align: center;
    gap: 0.3rem;
}


.subscribe-button {
    text-decoration: none;
    color: white;
    background-color: var(--primary-color);
    border-top: 0.063rem solid white;
    border-right: 0.063rem solid #333333;
    border-bottom: 0.063rem solid #333333;
    border-left: 0.063rem solid #CCCCCC;
    font: bold calc(1rem + 0.3vw) Arial;
}

.icon {
    color: var(--primary-color);
    height: 2rem;
    width: 2rem;
}

.logo img{
    height: 6.25rem;
    width: 11.25rem;
}

.navbar-link {
    height: 0.625rem;
    text-decoration: none;
    color: black
}

h6 {
    font-size: calc(1rem + 0.5vw); 
    font-weight: 50;
}

h6:hover {
    color: var(--primary-color)
        }

.merlin-pic img{
    height: 6.125rem;
    width: 6.125rem;
}

@media screen and (max-width:820px) {
   
    .logo {
        flex-basis: 10%;
    }

    .logo img {
        height: 8.44vh;
        width: 16.15vw;
    }
    
    .navbar-active {
        flex-basis: 80%;
        gap: 3rem;
    }
    
    .merlin-pic {
        flex-basis: 20%;
    }

    .merlin-pic img {
        width: 80px;
        height: 80px;
    }
    
    .navbar-right {
        flex-basis: 20%;
    }

    .icon {
        color: var(--primary-color);
        height: 1.5rem;
        width: 1.5rem;
    }
}

@media screen and (max-width:600px) {
  
    .logo img {
        height: 60px;
        width: 100px;
    }

    .navbar-active {
        gap: 1rem;
    }
    
    .merlin-pic {
        margin-right: 0rem;
    }

    .merlin-pic img {
        width: 30px;
        height: 30px;
    }

    .icon {
        height: 1rem;
        width: 1rem;
    }
    
}

@media screen and (max-width:400px) {

    .navbar-top {
        padding-bottom: 1rem;
    }
  
    .logo img {
        height: 40px;
        width: 80px;
    }
    
    .navbar-active {
        gap: 1rem;
        margin-left: 0rem;
        margin-right: 0rem;
        
    }
    
    .navbar-right {
        gap: 0rem;
    }

    .icon {
        color: var(--primary-color);
        height: 0.8rem;
        width: 0.8rem; 
    }

    .subscribe-button {
        font: bold calc(0.8rem + 0.1vw) Arial;
    }
}

