.drafts h1 {
    font-size: 48px;
}

.drafts-link{
    text-decoration: none;
}

.drafts-link p {
    font-size: 24px;
}