.add-photo {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.add-photo .add-photo-text{
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: block;
    }

.add-photo button{
    cursor: pointer;
    color: white;
    /* margin-top: 10px; */
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    width: 100px;
}