.write {
    margin-top: 100px;
    display: flex;
    gap: 20px;
}

.write .write-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 5;
}

.write .write-content input {
    padding: 10px;
    border: 1px solid lightgrey;
}

.editorContainer {
    height: 800px;
    width: 1400px;
    overflow: scroll;
    border: 1px solid lightgrey;
}

.editorContainer .editor {
    height: 100%;
    border: none;
}

.write-menu {
    margin-left: auto;
    margin-right: auto;
}

.write-menu .write-item {
    border: 2px solid lightgrey;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    gap: 10px;
    margin: auto;
    align-items: center;
}

.write-menu .write-item h1 {
    font-size: 20px;
    color: black;
}

.write-buttons {
    display: flex;
    gap: 20px;
}

.write-buttons :first-child {
    cursor: pointer;
    color: white;
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 700;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    width: 100px;
}

.write-buttons :last-child{
    cursor: pointer;
    padding: 3px 5px;
    cursor: pointer;
    color: white;
    /* margin-top: 10px; */
    background: var(--primary-color);
    height: 40px;
    border-radius: 10px;
    font-weight: 700;
    box-shadow: 4px 4px 4px #cbced1, -4px -4px 4px white;
    text-decoration: none;
    width: 80px;
}

.write-category {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.write-category label {
    margin-bottom: 10px
}

.write-error {
    color: red
}